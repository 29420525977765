<template>
  <b-carousel
    id="carousel-example-generic"
    controls
    indicators
  >
    <b-carousel-slide
      v-for="image in images"
      :key="image.IdImage"
      :img-src="image.image"
    />
  </b-carousel>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue'

export default {
  components: {
    BCarousel,
    BCarouselSlide,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
}
</script>
