<template>
  <b-card
    no-body
    class="border border-dark shadow-none"
  >
    <b-card-header
      role="tab"
    >
      <slot name="header">
        <ProductCard
          :product="product"
          :is-visible="visible"
          :collapse-item-id="collapseItemId"
          :origin-product-id="originProductId"
          :show-edit-button="showEditButton"
          :show-toggle-button="showPiecesList"
          :show-delete-button="showDeleteButton"
          :show-add-pieces-buttons="showAddPiecesButtons"
          :show-delete-pieces-buttons="showDeletePiecesButtons"
          :show-add-to-circuit-button="showAddToCircuitButton"
          :show-transfer-pieces-button="showTransferPiecesButton"
          :by-wholesaler="byWholesaler"
          @hide-modal="hideModal()"
          @reload-list="reloadList()"
          @transfer-pieces="transferPieces()"
          @select-all-rows="selectAllRows()"
        />
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemId"
      v-model="visible"
      role="tabpanel"
    >
      <b-card-body
        v-if="showPiecesList && product.pieces"
        class="px-0 py-0"
      >
        <slot v-if="visible">
          <PiecesList
            v-if="product.pieces.length > 0"
            ref="piecesList"
            :product="product"
            :pieces="product.pieces"
            :highlight-text="highlightText"
            :selectable="selectablePiecesList"
            :load-circuit-products="loadCircuitProducts"
            :show-actions-button="showAddPiecesButtons || showDeletePiecesButtons"
          />
          <b-alert
            v-else
            variant="warning"
            class="mb-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron piezas.</span>
            </div>
          </b-alert>
        </slot>
      </b-card-body>
      <b-card-body
        v-else-if="externalPieces.length > 0"
        class="px-0 py-0"
      >
        <slot v-if="visible">
          <PiecesList
            ref="piecesList"
            :product="product"
            :pieces="externalPieces"
            :highlight-text="highlightText"
            :selectable="selectablePiecesList"
            :load-circuit-products="loadCircuitProducts"
            :show-actions-button="showAddPiecesButtons || showDeletePiecesButtons"
          />
        </slot>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse, BAlert,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

import ProductCard from './ProductCard.vue'

export default {
  name: 'OutcomesCollapsableProduct',
  components: {
    BCard,
    PiecesList: () => import('./pieces/PiecesList.vue'),
    BAlert,
    BCardBody,
    BCollapse,
    BCardHeader,
    ProductCard,
  },
  props: {
    byWholesaler: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      required: true,
    },
    originProductId: {
      type: Number,
      required: true,
    },
    loadCircuitProducts: {
      type: Boolean,
      default: false,
    },
    externalPieces: {
      type: Array,
      default: () => [],
    },
    // * Pieces list
    showPiecesList: {
      type: Boolean,
      required: true,
    },
    selectablePiecesList: {
      type: Boolean,
      required: true,
    },
    // * Card buttons
    showTransferPiecesButton: {
      type: Boolean,
      default: false,
    },
    showAddToCircuitButton: {
      type: Boolean,
      default: false,
    },
    showAddPiecesButtons: {
      type: Boolean,
      default: false,
    },
    showDeletePiecesButtons: {
      type: Boolean,
      default: false,
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
    showEditButton: {
      type: Boolean,
      default: false,
    },
    // * highlight text by seracher
    highlightText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemId: '',
      openOnHover: this.$parent.hover,
    }
  },
  created() {
    this.collapseItemId = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    hideModal() {
      this.$emit('hide-modal')
    },
    transferPieces() {
      this.$emit('transfer-pieces')
    },
    reloadList() {
      this.$emit('reload-list')
    },
    selectAllRows() {
      this.$refs.piecesList.selectAllRows()
    },
  },
}
</script>
