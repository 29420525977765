<template>
  <div>
    <!-- modal vertical center -->
    <b-modal
      id="carousel-modal"
      ref="carousel-modal"
      :title="title"
      centered
      :hide-footer="hideFooter"
    >
      <b-card-text class="mb-1">
        <BasicCarousel :images="images" />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBModal, BCardText } from 'bootstrap-vue'
import BasicCarousel from '@/components/carousels/BasicCarousel.vue'

export default {
  components: {
    BModal,
    BCardText,
    BasicCarousel,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      hideFooter: true,
    }
  },
  methods: {
    showModal() {
      this.$refs['carousel-modal'].show()
    },
  },
}
</script>
