<template>
  <div
    class="my-1"
  >
    <div class="mb-0">
      <h5 class="font-weight-bolder">
        {{ title }}
      </h5>
    </div>
    <div
      v-for="(tag, index) in tags"
      :key="index"
    >
      <!-- #region::String type -->
      <div v-if="tag.type === 'string'">
        {{ tag.value | placeholder('Sin especificar') }} {{ tag.value ? tag.append : '' }}
      </div>
      <!-- #endregion::String type -->

      <!-- #region::Currency type -->
      <div v-if="tag.type === 'currency'">
        <template v-if="tag.value">
          <div class="d-flex">
            <slot
              v-if="tag.prepend && tag.prepend.includes('Oferta')"
              name="selected-offer-price"
            />
            <span :style="tag.style">{{ tag.prepend }} {{ tag.value | currency }}</span>
          </div>
        </template>
        <template v-else>
          {{ tag.prepend }} Sin especificar
        </template>
      </div>
      <!-- #endregion::Currency type -->

      <!-- #region::Currency type -->
      <div v-if="tag.type === 'currency-small'">
        <small v-if="tag.value">
          {{ tag.prepend }} {{ tag.value | currency }}
        </small>
        <small v-else>
          {{ tag.prepend }} Sin especificar
        </small>
      </div>
      <!-- #endregion::Currency type -->

      <!-- #region::Color type -->
      <div v-if="tag.type === 'color'">
        <SimpleColorTag :color="{name: tag.name, hex: tag.value}" />
      </div>
      <!-- #endregion::Color type -->

      <!-- #region::Date type -->
      <div v-if="tag.type === 'date'">
        <template v-if="tag.value">
          {{ tag.value | moment("DD/MM/YYYY") }}
        </template>
        <template v-else>
          {{ tag.prepend }} Sin especificar
        </template>
      </div>
      <!-- #endregion::Date type -->

      <!-- #region::Date description type -->
      <div v-if="tag.type === 'date-d'">
        <template v-if="tag.value">
          {{ tag.value | moment("D MMM YYYY") }}
        </template>
        <template v-else>
          {{ tag.prepend }} Sin especificar
        </template>
      </div>
      <!-- #endregion::Date description type -->
    </div>
  </div>
</template>

<script>
import SimpleColorTag from '@/components/colors/SimpleColorTag.vue'

export default {
  components: {
    SimpleColorTag,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.$moment.locale('es')
  },
}
</script>

<style lang="scss" scoped>
.no-shadow {
  box-shadow: none !important;
}
</style>
