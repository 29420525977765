<template>
  <b-card
    no-body
    class="shadow-none bg-transparent border-0"
  >
    <b-card-header
      role="tab"
      class="border-bottom mb-1"
    >
      <slot name="header">
        <feather-icon icon="ClockIcon" />
        <p class="mr-auto mb-0 ml-1">
          {{ dateFomat(outcomes.id) }}
        </p>
        <b-button
          :variant="'flat-primary'"
          class="btn-icon rounded-circle ml-1"
          @click="collapse"
        >
          <feather-icon :icon="iconToggle" />
        </b-button>
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemId"
      v-model="visible"
      role="tabpanel"
      class="rounded"
      style="overflow: hidden"
    >
      <b-card-body class="m-0 p-0 rounded">
        <slot>
          <app-collapse>
            <OutcomesCollapsableOrder
              v-for="order in ordersOutcomes"
              :key="order.id"
              :order="order"
              :highlight-text="true"
            />
          </app-collapse>
        </slot>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCardBody, BCollapse,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import OutcomesCollapsableOrder from '@/modules/store/outcomes/components/OutcomesCollapsableOrder.vue'

export default {
  name: 'OutcomesCollapsableItem',
  components: {
    BCard,
    BCardBody,
    BCollapse,
    BCardHeader,
    BButton,
    AppCollapse,
    OutcomesCollapsableOrder,
  },
  props: {
    outcomes: {
      type: Object,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemId: '',
      openOnHover: this.$parent.hover,
      iconToggle: 'ChevronDownIcon',
      ordersOutcomes: [],
    }
  },
  created() {
    this.collapseItemId = uuidv4()
    this.visible = this.isVisible
    let dataOrders = []
    Object.keys(this.outcomes).forEach(key => {
      if (key !== 'id') {
        dataOrders = [...dataOrders, {
          id: key,
          ...this.outcomes[key],
        }]
      }
    })
    this.ordersOutcomes = dataOrders
  },
  methods: {
    collapse() {
      this.visible = !this.visible
      this.setToggleIcon()
    },
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
    dateFomat(date) {
      const dateString = JSON.stringify(date)
      const parseDate = `${dateString?.split('"')[1]}T00:00:00`
      const fecha = new Date(parseDate)
      const options = {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
      }
      return fecha.toLocaleDateString('es-ES', options)
    },
  },
}
</script>
