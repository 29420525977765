<template>
  <div>
    <b-row align-v="center">
      <b-col
        md="12"
        class="mt-1"
      >
        <b-carousel
          id="carousel-product-card"
          controls
          indicators
        >
          <b-carousel-slide
            v-for="(image, index) in imagesSmall"
            :key="index"
            :img-src="image.image"
          >
            <template v-slot:img>
              <div :class="{ 'transbox' : !available }">
                <img
                  class="img full-width"
                  :src="image.image"
                  alt="image slot"
                >
                <small
                  v-if="!available"
                  class="centered"
                >No disponible</small>
              </div>
            </template>
          </b-carousel-slide>
        </b-carousel>
        <CarouselModal
          ref="carouselModal"
          :title="title"
          :images="imagesFull"
        />
      </b-col>
      <b-col>
        <b-button
          class="btn-icon inside-button"
          block
          variant="flat-primary"
          :disabled="!available"
          @click="showCarouselModal"
        >
          <feather-icon
            icon="Maximize2Icon"
          />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCarousel, BCarouselSlide, BButton, BRow, BCol,
} from 'bootstrap-vue'
import 'swiper/css/swiper.css'

import CarouselModal from '../modals/CarouselModal.vue'

export default {
  components: {
    BCarousel,
    BCarouselSlide,
    BButton,
    BRow,
    BCol,
    CarouselModal,
  },
  props: {
    expandible: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    imagesSmall: {
      type: Array,
      required: true,
    },
    imagesFull: {
      type: Array,
      required: true,
    },
    available: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    showCarouselModal() {
      this.$refs.carouselModal.showModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.swiper {
  ::v-deep .swiper-wrapper {
    flex-direction: row !important;
  }
  .swiper-slide {
    margin-top: 20px;
  }
}

.inside-button {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

img {
  max-inline-size: 100%;
  block-size: auto;
  object-fit: cover;
}

div.transbox {
  opacity: 0.6;
}

div.transbox small {
  font-weight: bold;
  color: rgb(255, 255, 255);
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

</style>
