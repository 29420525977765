<template>
  <b-col
    cols="12"
    md="auto"
    class="px-0"
  >
    <!-- #region begin::Delete dropdown button -->
    <b-dropdown
      v-b-tooltip.hover.top="isHomePage ? 'Eliminar' : ''"
      :variant="isHomePage ? 'flat-secondary' : 'delete-btn'"
      :class="[isHomePage ? 'btn-icon rounded-circle': 'delete-btn']"
      class="dropdown-icon-wrapper"
    >
      <template #button-content>
        <feather-icon
          icon="Trash2Icon"
          :class="[isHomePage ? '' : 'mr-50 text-white']"
        />
        <span
          v-if="!isHomePage"
          class="text-white"
        >Eliminar</span>
      </template>
      <b-dropdown-item
        @click="showConfirmDeleteProductAndPieces()"
      >
        {{ unavailablePieces ? 'Eliminar producto' : 'Eliminar producto y sus piezas' }}
      </b-dropdown-item>
      <b-dropdown-item
        :disabled="unavailablePieces"
        @click="showConfirmDeleteProduct()"
      >
        {{ unavailablePieces ? 'Eliminar producto y sus piezas' : 'Eliminar sólo el producto' }}
      </b-dropdown-item>
    </b-dropdown>
    <!-- #endregion end::Delete dropdown button -->

    <TransferPiecesModal
      ref="transferModal"
      :origin-product-id="product.IdProduct"
      @transfer-pieces="transferPieces()"
    />
  </b-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BCol, BDropdown, BDropdownItem, VBTooltip,
} from 'bootstrap-vue'

import TransferPiecesModal from '@/modules/production/products/components/modals/TransferPiecesModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getError from '@/helpers/ErrorsHandler'

export default {
  components: {
    BCol,
    BDropdown,
    BDropdownItem,
    TransferPiecesModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getExternalPieces: 'pieces/getExternalPieces',
    }),
    /**
     * Piezas que se cagan en la lista
     */
    pieces: {
      get() { return this.getExternalPieces },
    },
    isHomePage() {
      if (this.$route.name === 'products-home') {
        return true
      }
      return false
    },
    unavailablePieces() {
      if (this.$route.name === 'product-details') {
        return this.pieces.length === 0
      }

      return !this.product.pieces || this.product.pieces.length === 0
    },
  },
  methods: {
    ...mapActions('products', ['deleteProduct']),
    async showConfirmDeleteProductAndPieces() {
      const result = await this.$swal({
        title: '¿Deseas continuar?',
        text: '¡La información del producto y la de sus piezas será eliminada!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        try {
          this.$swal({
            title: 'Espere por favor',
            allowOutsideClick: false,
          })

          this.$swal.showLoading()

          await this.deleteProduct(this.product.IdProduct)

          this.$swal.fire('Eliminado', '¡El producto ha sido eliminado correctamente!', 'success')

          if (this.isHomePage) {
            this.$emit('reload-list')
          } else {
            this.$router.push({ name: 'products-home' })
          }
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', getError(error), 'danger')
        }
      }
    },
    async showConfirmDeleteProduct() {
      const result = await this.$swal({
        title: '¿Deseas continuar?',
        text: '¡La información del producto y las piezas serán asignadas a otro producto!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        this.$refs.transferModal.showModal()
      }
    },
    transferPieces() {
      this.$emit('transfer-pieces')
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
