<template>
  <div>
    <!-- #region begin:title -->
    <b-form-row>
      <b-col
        cols="12"
        class="mb-1"
      >
        <h3>Salidas</h3>
      </b-col>
      <b-col
        cols="12"
        class="mb1"
      >
        <b-card-text>

          <!-- #region::Product list -->
          <OutcomesList
            resource="products"
            :highlight-text="true"
            :selectable-pieces-list="$ability.can('edit', 'Circuit')"
          />
          <!-- #endregion::Product list -->

        </b-card-text>
      </b-col>
    </b-form-row>
    <!-- #endregion end:title -->

  </div>
</template>

<script>
import {
  BCardText, BFormRow, BCol,
} from 'bootstrap-vue'

import OutcomesList from '@/modules/store/outcomes/components/OutcomesList.vue'

export default {
  name: 'OutcomeHome',
  components: {
    BCol,
    BFormRow,
    BCardText,
    OutcomesList,
  },
  data() {
    return {
      selectedTabIndex: 0,
    }
  },
  methods: {},
}
</script>
