<template>
  <div>
    <template v-if="formartedItems.length === 0">

      <!-- #region::Alert when no circuits are available -->
      <b-alert
        variant="warning"
        class="my-2"
        show
      >
        <div class="alert-body">
          <span>No se agregaron circuitos a la cotización.</span>
        </div>
      </b-alert>
      <!-- #endregion::Alert when no circuits are available -->

    </template>

    <template v-else>
      <b-form-row>

        <!-- #region::Title -->
        <b-col md="12">
          <h3 class="font-weight-bolder mt-2 ml-3">
            Circuitos
          </h3>
        </b-col>
        <!-- #endregion::Title -->

        <!-- #region::Circuit list -->
        <b-col>
          <b-card
            class="mt-1 border border-dark shadow-none"
            no-body
          >
            <b-card-text>

              <!-- #region::Circuits list -->
              <b-table
                :items="formartedItems"
                :fields="columns"
                responsive="sm"
                class="my-0"
              >

                <!-- #region::A virtual column for name -->
                <template #cell(Name)="{ detailsShowing, item }">
                  <div class="d-flex justify-content-start">
                    <h6
                      class="align-self-center"
                      :class="{ 'text-white': detailsShowing }"
                    >
                      {{ item.Name }}
                    </h6>
                    <div
                      v-if="item.SoldOut && item.SoldOut === 'Si'"
                      class="sm-banner-alert alert-yellow"
                      role="alert"
                    >
                      Vendido
                    </div>
                    <div
                      v-if="item.Delete && item.Delete === 'Si'"
                      class="sm-banner-alert alert-gray"
                      role="alert"
                    >
                      No disponible
                    </div>
                  </div>
                </template>
                <!-- #endregion::A virtual column for name -->

                <!-- #region::A virtual column for prices -->
                <template #cell(prices)="{ item }">
                  {{ item.PriceMin | currency }} - {{ item.PriceMax | currency }}
                </template>
                <!-- #endregion::A virtual column for prices -->

                <!-- #region::A virtual column for offer price -->
                <template #cell(OfferPrice)="{ item }">
                  <span class="text-nowrap">{{ item.OfferPrice | currency }}</span>
                </template>
                <!-- #endregion::A virtual column for offer price -->

                <!-- #region::A virtual column for total -->
                <template #cell(total)="{ item }">
                  {{ totalUnitPrices(item) | currency }}
                </template>
                <!-- #endregion::A virtual column for total -->

                <!-- #region::A virtual column for toggle button -->
                <template
                  v-if="$ability.can('read', 'Circuit')"
                  #cell(details)="{ detailsShowing, item }"
                >
                  <b-button
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    @click="toggleDetails(item)"
                  >
                    <feather-icon :icon="detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
                  </b-button>
                </template>
                <!-- #endregion::A virtual column for toggle button -->

                <!-- #region::Circuit details -->
                <template
                  v-if="$ability.can('read', 'Circuit')"
                  v-slot:row-details="{ item }"
                >
                  <b-card
                    no-body
                    class="mt-1"
                  >
                    <OutcomesCollapsableProduct
                      v-for="(product, index) in item.products"
                      :key="product.IdProduct"
                      :product="makeProduct(product)"
                      :is-visible="index == 0 ? true : false"
                      :highlight-text="highlightText"
                      :show-pieces-list="true"
                      :origin-product-id="originProductId"
                      :load-circuit-products="loadCircuitProducts"
                      :selectable-pieces-list="selectablePiecesList"
                    />
                  </b-card>
                </template>
                <!-- #endregion::Circuit details -->

              </b-table>
            </b-card-text>
          </b-card>
        </b-col>
        <!-- #endregion::Circuit list -->

      </b-form-row>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  BCol, BFormRow, BCard, BCardText, BTable, BButton, BAlert,
} from 'bootstrap-vue'

import buildProduct from '@/helpers/CircuitProductConverter'
import OutcomesCollapsableProduct from '@/modules/store/outcomes/components/OutcomesCollapsableProduct.vue'

export default {
  components: {
    BCol,
    BCard,
    BAlert,
    BTable,
    BButton,
    BFormRow,
    BCardText,
    OutcomesCollapsableProduct,
  },
  props: {
    quote: {
      type: Object,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    highlightText: {
      type: Boolean,
      default: false,
    },
    originProductId: {
      type: Number,
      default: 0,
    },
    loadCircuitProducts: {
      type: Boolean,
      default: false,
    },
    selectablePiecesList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      circuits: [],
      currentItems: [],
      columns: [
        {
          key: 'IdCircuit',
          label: 'No. Circuito',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Name',
          label: 'Nombre',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'prices',
          label: 'Precio',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'OfferPrice',
          label: 'Precio oferta',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'total',
          label: 'Total',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Qty',
          label: 'No. de productos',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Location',
          label: 'Ubicación',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'details',
          label: '',
          thClass: 'bg-light',
        },
      ],
    }
  },
  computed: {
    formartedItems() {
      if (!this.circuits) return []
      return this.circuits.map(item => {
        // eslint-disable-next-line no-underscore-dangle, no-param-reassign
        this.$set(item, 'total', 0)
        this.offRow(item)
        return item
      })
    },
  },
  created() {
    const quoteCircuits = this.quote.circuits
    this.circuits = quoteCircuits

    this.circuits.forEach(circuit => {
      circuit.products.forEach(product => {
        this.addQuotePieces(product.pieces)
      })
    })
  },
  methods: {
    ...mapActions({
      addQuotePieces: 'quotes/addQuotePieces',
    }),
    toggleDetails(row) {
      // eslint-disable-next-line no-underscore-dangle
      if (row._showDetails) {
        this.offRow(row)
      } else {
        for (let index = 0; index < this.currentItems.length; index += 1) {
          this.offRow(this.$set(this.currentItems[index]))
        }

        this.currentItems.forEach(item => {
          this.offRow(item)
        })

        this.$nextTick(() => {
          this.onRow(row)
        })
      }
    },
    offRow(row) {
      this.$set(row, '_showDetails', false)
      this.$set(row, '_cellVariants', {
        total: '', IdCircuit: '', Name: '', prices: '', OfferPrice: '', Qty: '', Location: '', details: '',
      })
    },
    onRow(row) {
      this.$set(row, '_showDetails', true)
      this.$set(row, '_cellVariants', {
        total: 'primary', IdCircuit: 'primary', Name: 'primary', prices: 'primary', OfferPrice: 'primary', Qty: 'primary', Location: 'primary', details: 'primary',
      })
    },
    totalUnitPrices(circuit) {
      let total = 0

      circuit.products.forEach(product => {
        total += product.product.UnitPrice * product.pieces.length
      })

      const baseCircuit = circuit
      baseCircuit.total = total

      return total
    },

    /**
    * Producto del circuito
    *
    * @summary Crea un producto que se adapta al componente del circuito. La informacion es proporcionada
    * por el servidor y es tratada para mostrarse de manera correcta
    * @param {object} circuitProduct - Producto del circuito proporcionado por el servidor
    * @return {object} producto que se muestra en el detalle de los circuitos
    */
    makeProduct(circuitProduct) {
      const product = buildProduct(circuitProduct)
      return product
    },
  },
}
</script>
