<template>
  <div>
    <b-row align-h="start">
      <b-col
        md="2"
        class="pr-0"
      >
        <canvas :style="style" />
      </b-col>
      <b-col md="10">
        <div>{{ color.name }}</div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    color: {
      type: Object,
      required: true,
    },
  },
  computed: {
    style() {
      return `background-color: ${this.color.hex}; width:20px;height:10x;"`
    },
  },
}
</script>
