<template>
  <div>

    <!-- #region begin::Transfer pieces modal -->
    <b-modal
      id="transfer-modal"
      ref="transfer-modal"
      title="Reasignar producto"
      centered
      size="xl"
    >
      <b-card-text>
        <b-row>
          <b-col
            cols="12"
            class="mb-1"
          >
            <h3>Productos</h3>
          </b-col>

          <!-- #region begin::Products list -->
          <b-col>
            <ProductsList
              resource="products"
              :show-pieces-list="true"
              :show-location-filter="false"
              :show-transfer-pieces-button="true"
              :origin-product-id="originProductId"
              @hide-modal="hideModal"
              @transfer-pieces="transferPieces()"
            />
          </b-col>
          <!-- #endregion end::Products list -->

        </b-row>
      </b-card-text>

      <!-- #region::Footer -->
      <template #modal-footer>
        <b-button
          variant="delete-btn"
          class="delete-btn"
          @click="hideModal"
        >
          Cancelar
        </b-button>
      </template>
      <!-- #endregion::Footer -->

    </b-modal>
    <!-- #endregion end::Transfer pieces modal -->

  </div>
</template>

<script>
import {
  BRow, BCol, BModal, VBModal, BCardText, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BButton,
    BCardText,
    ProductsList: () => import('@/modules/production/products/components/ProductsList.vue'),
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    originProductId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    showModal() {
      this.$refs['transfer-modal'].show()
    },
    hideModal() {
      this.$refs['transfer-modal'].hide()
    },
    transferPieces() {
      this.$emit('transfer-pieces')
    },
  },
}
</script>
