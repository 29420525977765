var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.formartedItems.length === 0)?[_c('b-alert',{staticClass:"my-2",attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("No se agregaron circuitos a la cotización.")])])])]:[_c('b-form-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h3',{staticClass:"font-weight-bolder mt-2 ml-3"},[_vm._v(" Circuitos ")])]),_c('b-col',[_c('b-card',{staticClass:"mt-1 border border-dark shadow-none",attrs:{"no-body":""}},[_c('b-card-text',[_c('b-table',{staticClass:"my-0",attrs:{"items":_vm.formartedItems,"fields":_vm.columns,"responsive":"sm"},scopedSlots:_vm._u([{key:"cell(Name)",fn:function(ref){
var detailsShowing = ref.detailsShowing;
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-start"},[_c('h6',{staticClass:"align-self-center",class:{ 'text-white': detailsShowing }},[_vm._v(" "+_vm._s(item.Name)+" ")]),(item.SoldOut && item.SoldOut === 'Si')?_c('div',{staticClass:"sm-banner-alert alert-yellow",attrs:{"role":"alert"}},[_vm._v(" Vendido ")]):_vm._e(),(item.Delete && item.Delete === 'Si')?_c('div',{staticClass:"sm-banner-alert alert-gray",attrs:{"role":"alert"}},[_vm._v(" No disponible ")]):_vm._e()])]}},{key:"cell(prices)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.PriceMin))+" - "+_vm._s(_vm._f("currency")(item.PriceMax))+" ")]}},{key:"cell(OfferPrice)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("currency")(item.OfferPrice)))])]}},{key:"cell(total)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalUnitPrices(item)))+" ")]}},(_vm.$ability.can('read', 'Circuit'))?{key:"cell(details)",fn:function(ref){
var detailsShowing = ref.detailsShowing;
var item = ref.item;
return [_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.toggleDetails(item)}}},[_c('feather-icon',{attrs:{"icon":detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'}})],1)]}}:null,(_vm.$ability.can('read', 'Circuit'))?{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('b-card',{staticClass:"mt-1",attrs:{"no-body":""}},_vm._l((item.products),function(product,index){return _c('OutcomesCollapsableProduct',{key:product.IdProduct,attrs:{"product":_vm.makeProduct(product),"is-visible":index == 0 ? true : false,"highlight-text":_vm.highlightText,"show-pieces-list":true,"origin-product-id":_vm.originProductId,"load-circuit-products":_vm.loadCircuitProducts,"selectable-pieces-list":_vm.selectablePiecesList}})}),1)]}}:null],null,true)})],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }