<template>
  <b-card class="blue-card">

    <b-row class="d-flex justify-content-between flex-nowrap minWidth">
      <div class="px-1 d-flex justify-content-between flex-grow">
        <div class="px-1">
          <router-link
            :to="{ name: 'order-details', params: { id: headOrder.order.ID } }"
            style="color: LightSkyBlue; font-weight: bold;"
          >
            <InformationBasicCard
              title="Order"
              :tags="[{ value: headOrder.order.ID, type: 'string' }]"
            />
          </router-link>
        </div>
        <div class="px-1">
          <InformationBasicCard
            title="Folio salida"
            :tags="[{ value: (headOrder.id ? headOrder.id : 'Sin especificar') , type: 'string' }]"
          />
        </div>
        <div class="px-1">
          <InformationBasicCard
            title="T.C."
            :tags="[{ value: (headOrder.order.ExchangeRate ? `${formatNumber(headOrder.order.ExchangeRate)}` : 'Sin especificar') , type: 'string' }]"
          />
        </div>
        <div class="px-1">
          <InformationBasicCard
            title="Solicitud de factura"
            :tags="[{ value: headOrder.order.invoice_required, type: 'string' }]"
          />
        </div>
        <div class="px-1">
          <InformationBasicCard
            title="Fecha de salida"
            :tags="[{ value: headOrder.order.output_date, type: 'string' }]"
          />
        </div>
        <div class="px-1">
          <InformationBasicCard
            title="Productos"
            :tags="[{ value: headOrder.order.Qty, type: 'string' }]"
          />
        </div>
        <div
          v-if="role !== 'Coordinador de Almacen'"
          class="px-1"
        >
          <InformationBasicCard
            title="Cliente"
            :tags="[{ value: `${headOrder.client.name} ${headOrder.client.last_name ? headOrder.client.last_name : ''}`, type: 'string' }]"
          />
        </div>
        <div class="px-1">
          <InformationBasicCard
            title="Vendedor"
            :tags="[{ value: headOrder.order.created_by, type: 'string' }]"
          />
        </div>
      </div>
      <div class="px-1 my-auto ml-auto arrow">
        <b-button
          :variant="'flat-secondary'"
          class="btn-icon rounded-circle"
          @click="$emit('click')"
        >
          <feather-icon
            :icon="iconToggle"
            class="text-light"
          />
        </b-button>
      </div>
    </b-row>

  </b-card>
</template>

<script>
import {
  BButton, BRow, BCard,
} from 'bootstrap-vue'

import InformationBasicCard from '@/components/cards/InformationBasicCard.vue'

export default {
  name: 'OutcomeExtraData',
  components: {
    BRow,
    BCard,
    BButton,
    InformationBasicCard,
  },
  props: {
    headOrder: {
      type: Object,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      quoteInfo: null,
      iconToggle: 'ChevronDownIcon',
      visible: false,
      role: '',
    }
  },
  watch: {
    isVisible(val) {
      this.iconToggle = val ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
  },
  async created() {
    // const [quoteInfo] = this.quote
    // console.log('this.quote', this.quote)
    // this.quoteInfo = quoteInfo
    this.visible = this.isVisible
    this.getUseRole()
  },
  methods: {
    collapse() {
      this.visible = !this.visible
      this.setToggleIcon()
    },
    getUseRole() {
      const user = JSON.parse(localStorage?.userData)
      this.role = user?.Role
    },
    formatNumber(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
      return formatter.format(value)
    },
  },
}
</script>

<style lang="scss" scoped>
b-card {
  background-color: blue;
}
.blue-card{
  overflow-x: auto;
}
.minWidth{
  min-width: 800px;
}
.flex-grow{
  flex-grow: 1;
  max-width: 1000px;
}

.arrow {
  width: 90px;
}
</style>
