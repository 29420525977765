<template>
  <b-card
    no-body
    class="shadow-none"
  >
    <b-card-header
      role="tab"
      class="p-0 m-0"
    >
      <slot name="header">
        <div class="col-12 p-0 m-0">
          <OutcomeExtraData
            :head-order="order"
            :is-visible="visible"
            @click="collapse"
          />
        </div>
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemId"
      v-model="visible"
      role="tabpanel"
    >
      <b-card-body class="m-0 p-0">
        <slot>
          <OutcomesCollapsableProduct
            v-for="(product, index) in productOrder"
            :key="product.IdProduct"
            :product="product"
            :is-visible="index == 0 ? true : false"
            :highlight-text="highlightText"
            :show-pieces-list="true"
            :origin-product-id="originProductId"
            :load-circuit-products="loadCircuitProducts"
            :by-wholesaler="byWholesaler"
            :selectable-pieces-list="selectablePiecesList"
          />
          <slot v-if="order.items.circuits.length > 0">
            <OutcomesCircuitsDetails
              :quote="order.items"
              :highlight-text="highlightText"
            />
          </slot>
        </slot>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BCard, BCardHeader, BCardBody, BCollapse,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import OutcomeExtraData from '@/modules/store/outcomes/components/OutcomeExtraData.vue'
import OutcomesCollapsableProduct from '@/modules/store/outcomes/components/OutcomesCollapsableProduct.vue'
import OutcomesCircuitsDetails from '@/modules/store/outcomes/components/OutcomesCircuitsDetails.vue'
import buildProduct from '@/helpers/ProductConverter'

export default {
  name: 'OutcomesCollapsableOrder',
  components: {
    BCard,
    BCardBody,
    BCollapse,
    BCardHeader,
    OutcomeExtraData,
    OutcomesCollapsableProduct,
    OutcomesCircuitsDetails,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    highlightText: {
      type: Boolean,
      default: false,
    },
    originProductId: {
      type: Number,
      default: 0,
    },
    loadCircuitProducts: {
      type: Boolean,
      default: false,
    },
    selectablePiecesList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemId: '',
      openOnHover: this.$parent.hover,
      iconToggle: 'ChevronDownIcon',
      productOrder: [],
      byWholesaler: false,
      isLoadingProducts: false,
      resource: 'products',
      quote: null,
    }
  },
  computed: {
    ...mapGetters({}),
  },
  async created() {
    this.collapseItemId = uuidv4()
    this.visible = this.isVisible
    const formattedProducts = []
    this.order.items.products.forEach(quoteProduct => {
      console.log('quoteProduct', quoteProduct?.product)
      // eslint-disable-next-line no-unused-expressions
      quoteProduct?.product?.byWholesaler && (this.byWholesaler = quoteProduct?.product?.byWholesaler)
      const product = buildProduct(quoteProduct)
      formattedProducts.push(product)
    })
    this.productOrder = formattedProducts
  },
  methods: {
    collapse() {
      this.visible = !this.visible
    },
    ...mapActions({
    }),
  },
}
</script>
