<template>
  <div>

    <!-- #region begin::Searcher and filters -->
    <div>
      <OutcomestListFilters
        @on:filter="handleFilter"
      />
    </div>
    <!-- #endregion begin::Searcher and filters -->

    <!-- #region::Skeleton -->
    <template v-if="isLoadingOutcomes">
      <b-skeleton
        class="mt-1"
        animation="wave"
        width="100%"
        height="430px"
      />
    </template>
    <!-- #region::Skeleton -->

    <!-- #region begin::Products list -->
    <template v-else>

      <!-- #region::Collapsable items -->
      <app-collapse>
        <OutcomesCollapsableItem
          v-for="(outcomes, index) in dataOutcomes"
          :key="index"
          :outcomes="outcomes"
        />
      </app-collapse>
      <!-- #endregion::Collapsable items -->

      <!-- #region begin::Alert when no outcomes are available -->
      <b-alert
        v-if="!isLoadingOutcomes && !availableOutcomes"
        variant="warning"
        class="mt-2"
        show
      >
        <div class="alert-body">
          <span>No se encontraron salidas.</span>
        </div>
      </b-alert>
      <!-- #endregion end::Alert when no outcomes are available -->

    </template>
    <!-- #endregion end::Products list -->

    <!-- #region begin::Pagination & items per list -->
    <BasicPaginator
      v-if="availableOutcomes"
      ref="basic-paginator"
      class="mt-2"
      :callback="handleChangePagination"
      :total-rows="totalOutcomes"
    />
    <!-- #endregion end::Pagination & items per list -->

  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import { BSkeleton, BAlert } from 'bootstrap-vue'

import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import OutcomestListFilters from '@/modules/store/outcomes/components/OutcomestListFilters.vue'
import OutcomesCollapsableItem from '@/modules/store/outcomes/components/OutcomesCollapsableItem.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  name: 'OutcomesList',
  components: {
    BAlert,
    BSkeleton,
    AppCollapse,
    BasicPaginator,
    OutcomestListFilters,
    OutcomesCollapsableItem,
  },

  props: {
    showOptionsButtons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lastPage: 0,
      dataOutcomes: [],
      totalOutcomes: 0,
      isLoadingOutcomes: false,
    }
  },
  computed: {
    ...mapGetters({
      getFilteredPerPage: 'filters/getFilteredPerPage',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredCurrentPage: 'filters/getFilteredCurrentPage',
      getFilteredCreatedAtDate: 'filters/getFilteredCreatedAtDate',
    }),
    /**
     * Indica la cantidad de elementos por página (paginado global)
     */
    filteredPerPage: {
      get() { return this.getFilteredPerPage },
      set(value) { this.setFilteredPerPage(value) },
    },
    /**
     * Término de búsqueda filtrado (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
      set(value) { this.setFilteredSearchTerm(value) },
    },
    /**
     * Indica la página actual (paginado global)
     */
    filteredCurrentPage: {
      get() { return this.getFilteredCurrentPage },
      set(value) { this.setFilteredCurrentPage(value) },
    },
    /**
     * Fecha de creación filtrada (filtro en store)
     */
    filteredCreatedAtDate: {
      get() { return this.getFilteredCreatedAtDate },
      set(value) { this.setFilteredCreatedAtDate(value) },
    },
    availableOutcomes() {
      return this.totalOutcomes > 0
    },
  },

  /**
  * Hook que se ejecuta cuando el componente es montado
  *
  * @summary Emite el evento cuando el componente ha sido montado
  */
  mounted() {
    this.$emit('product-list-mounted')
  },

  /**
  * Hook que se ejecuta cuando el componente es creado
  *
  * @summary Verifica que no se hayan cargado los filtros globales y carga la lista de productos
  */
  async created() {
    this.loadOutcomesWithVerification()
  },
  methods: {
    ...mapActions({
      loadOutcomes: 'outcomes/getOutcomes',
      setFilteredPerPage: 'filters/setFilteredPerPage',
      setFilteredSearchTerm: 'filters/setFilteredSearchTerm',
      setFilteredCurrentPage: 'filters/setFilteredCurrentPage',
      setFilteredCreatedAtDate: 'filters/setFilteredCreatedAtDate',
    }),

    /**
    * Evento de filtrado
    *
    * @summary Atiende el evento de filtrado del compontente filter, asignando
    * términos de búsqueda en base a los campos de selección y el buscador así como
    * establecer los filtros globales en el store
    * @param {Object} filter - Objeto con el tipo de filtro y el valor
    */
    async handleFilter(filter) {
      switch (filter.type) {
        case 'date': this.filteredCreatedAtDate = filter.value
          break
        default:
          this.filteredSearchTerm = filter.value
          this.filteredCurrentPage = 1
          // eslint-disable-next-line no-unused-expressions
          this.$refs['basic-paginator']?.resetCurrentPage()
          break
      }

      this.loadOutcomesWithVerification()
    },

    /**
    * Carga de elementos de la lista con verificación de paginado
    *
    * @summary Carga la lista de elementos y verifica que la página actual contenga elementos
    */
    async loadOutcomesWithVerification() {
      await this.loadOutcomesList()
      this.verifyContenList()
    },

    /**
    * Evento de filtrado
    *
    * @summary Evento del componente de filtrado. Devuelve los registros en base
    * al tipo de filtro y recarga la lista de traspasos
    * @param filter - Objeto con el tipo y valor del filtro
    */
    async handleChangePagination(currentPage, perPage) {
      this.filteredCurrentPage = currentPage
      this.filteredPerPage = perPage
      this.loadOutcomesList()
    },

    /**
    * Verificación de contenido
    *
    * @summary Verifica que la página actual tenga registros, en caso de no tenerlos
    * tomará la última página disponible y cargará los registros
    */
    async verifyContenList() {
      if (this.dataOutcomes.length === 0) {
        this.filteredCurrentPage = this.lastPage

        // Verifica si hay registros disponibles en el servidor
        if (this.availableOutcomes) {
          this.$refs['basic-paginator'].resetCurrentPage(this.lastPage)
          await this.loadOutcomesList()
        }
      }
    },

    /**
    * Carga de lista de salidas
    *
    * @summary Atiende la carga de la lista de salidas y establece
    * los valores de la paginación
    * @exception Muestra un mensaje de error en caso de que la petición
    * al servidor falle
    */
    async loadOutcomesList() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()
        this.isLoadingOutcomes = true

        const response = await this.loadOutcomes(this.loadParams())

        const responseOutcomes = Object.keys(response.data.data.data).map(key => ({
          ...response.data.data.data[key],
          id: key,
        }))

        this.dataOutcomes = responseOutcomes
        this.totalOutcomes = response.data.data.total
        this.lastPage = response.data.data.last_page

        this.isLoadingOutcomes = false
        this.$swal.close()
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * Parámetros de carga
    *
    * @summary Devuelve los parámetros de carga de la lista
    */
    loadParams() {
      return {
        perPage: this.filteredPerPage,
        dateTerm: this.filteredCreatedAtDate,
        searchTerm: this.filteredSearchTerm,
        currentPage: this.filteredCurrentPage,
      }
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
