<template>
  <div>
    <b-modal
      id="circuit-modal"
      ref="circuit-modal"
      title="Agregar a un circuito"
      centered
      size="xl"
    >
      <b-card-text>
        <CircuitsList
          :show-edit-button="false"
          :show-download-file="false"
          :show-toggle-button="false"
          :show-delete-button="false"
          :show-add-to-circuit-button="true"
          :can-apply-global-filters="false"
          :can-apply-global-pagination="false"
          @on-selected-circuit="onSelectedCircuit"
        />
      </b-card-text>

      <!-- #region::Footer -->
      <template #modal-footer>
        <b-button
          variant="delete-btn"
          class="delete-btn"
          @click="hideModal"
        >
          Cancelar
        </b-button>
      </template>
    <!-- #endregion::Footer -->

    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  BModal, VBModal, BCardText, BButton,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BCardText,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    CircuitsList: () => import('@/modules/production/circuits/views/CircuitsList.vue'),
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      newPieces: [],
    }
  },
  beforeDestroy() {
    this.clearFormData()
  },
  methods: {
    ...mapActions({
      updateCircuit: 'circuits/updateCircuit',
      clearFormData: 'circuits/clearFormData',
    }),
    showModal(pieces) {
      this.newPieces = pieces
      this.$refs['circuit-modal'].show()
    },
    hideModal() {
      this.$refs['circuit-modal'].hide()
    },
    async onSelectedCircuit(circuit) {
      const circuitPieceLocation = circuit.Location !== '' ? circuit.Location : this.newPieces[0].Location
      const newPieceLocation = this.newPieces[0].Location

      if (circuitPieceLocation === newPieceLocation) {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        const formData = new FormData()

        formData.append('id', circuit.IdCircuit)
        formData.append('name', circuit.Name)

        circuit.products.forEach(product => {
          product.detailCircuit.forEach(detail => {
            const pieceData = this.buildPieceData(detail.piece, false, false)
            formData.append('pieces[]', pieceData)
          })
        })

        this.newPieces.forEach(piece => {
          const pieceData = this.buildPieceData(piece, false, true)
          formData.append('pieces[]', pieceData)
        })

        // for (const value of formData.values()) {
        //   console.log(value)
        // }

        try {
          await this.updateCircuit(formData)
          this.$swal.fire({
            title: 'Guardado',
            text: '¡Las piezas se han agregado al circuito correctamente!',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Continuar',
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.$emit('reload-list')
            }
          })
        } catch (error) {
          this.$swal.close()
          this.showToast(
            'Error de actualización',
            error.response.data.message,
            'danger',
          )
        }
      } else {
        this.showToast(
          'Ubición incorrecta',
          'El circuito seleccionado no pertenece a la ubicación de las piezas seleccionadas.',
          'warning',
        )
      }
    },
    buildPieceData(piece, mustDelete, isNew) {
      let pieceData = null

      pieceData = JSON.stringify({
        idProduct: piece.FK_IdProduct,
        idPieza: piece.IdPiece,
        delete: mustDelete,
        new: isNew,
      })

      return pieceData
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
