<template>
  <div>
    <!-- #region begin::Searcher and options for filtering -->
    <b-form-row>

      <!-- #region begin::Searcher and filters -->
      <b-col md="8">
        <b-form-row align-h="start">
          <b-col md="4">
            <BasicSearcher
              ref="basic-searcher"
              :callback="handleSearch"
              placeholder="Buscar productos"
            />
          </b-col>

          <!-- #endregion begin::Brand filter -->

          <!-- #region::Date filter -->
          <b-col md="4">
            <b-form-datepicker
              id="example-datepicker"
              v-model="selectedDate"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              placeholder="Fecha de creación"
              local="es"
              reset-button
            />
          </b-col>
          <!-- #endregion::Date filter -->

        </b-form-row>
      </b-col>
      <!-- #endregion begin::Searcher and filters -->

    </b-form-row>
    <!-- #endregion end::Searcher and options for filtering -->

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCol, BFormRow, BFormDatepicker,
} from 'bootstrap-vue'

import BasicSearcher from '@/components/forms/BasicSearcher.vue'

export default {
  components: {
    BCol,
    BFormRow,
    BasicSearcher,
    BFormDatepicker,
  },
  data() {
    return {
      selectedDate: '',
    }
  },
  computed: {
    ...mapGetters({
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredCreatedAtDate: 'filters/getFilteredCreatedAtDate',
    }),
    /**
     * Término de búsqueda filtrado (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
    },
    /**
     * Fecha de creación filtrada (filtro en store)
     */
    filteredCreatedAtDate: {
      get() { return this.getFilteredCreatedAtDate },
    },
  },

  /**
  * Carga información del estado
  *
  * @summary Si existe información relacionada con los filtros cargados en el store,
  * se cargan en el componente
  */
  mounted() {
    // Carga el término de búsqueda (filtro en store)
    if (this.filteredSearchTerm) {
      this.$refs['basic-searcher'].loadSearchTerm(this.filteredSearchTerm)
    }

    this.selectedDate = this.filteredCreatedAtDate || ''
    this.$watch('selectedDate', date => {
      this.$emit('on:filter', { type: 'date', value: date })
    })
  },

  methods: {
    /**
    * Evento del buscador básico
    *
    * @summary Emite un evento con el término de búsqueda y limpia los campos de selección
    * @param {String} searchTerm - Término de búsqueda
    */
    handleSearch(searchTerm) {
      this.$emit('on:filter', { type: 'search', value: searchTerm })

      this.selectedDate = ''
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
[class*="collapse-"] {
  .card {
    .card-header {
      cursor: auto;
      padding: 1rem 2.8rem 1rem 1rem;
    }
  }
}
.style-chooser {
  background: #ffffff;
}

.col-padding {
  padding: 0 0.5rem 0 0.5rem;
}
</style>
